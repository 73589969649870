import React from "react";
import BasicNav from "./BasicNav";
import Footer from "./Footer";
import useTopNav from "../lib/useTopNav";
import capitalize from "lodash/capitalize";
import ExternalNav from "./ExternalNav";
import { LogoLink } from "./LogoLink";

export default ({ children }) => {
  const menuItems = useTopNav();
  return (
    <div>
      <header>
        <LogoLink />
        <ExternalNav />
        <hr />
        <h4>Content:</h4>
        <nav aria-label="Main">
          {menuItems.map(({ pathPrefix, title }) => (
            <li key={pathPrefix}>
              <a href={`/${pathPrefix}`}>{capitalize(title || pathPrefix)}</a>
            </li>
          ))}
        </nav>
        <hr />
      </header>
      {children}
      <hr />
      <Footer />
    </div>
  );
};
