import React from "react";
import { usePlatformLinks } from "../lib/usePlatformLinks";

export default ({ classes = {} }) => {
  const externalNavigation = usePlatformLinks()
  return (
    <ul>
      {externalNavigation.map(({ href, name, id }) => {
        return <li><a href={href}>{name}</a></li>
      })}
    </ul>
  );
};
