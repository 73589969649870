import { Link } from "gatsby";
import React from "react";
import Logo from "./Logo";

export const LogoLink = (props: Omit<LinkProps, "to">) => {
  return (
    <Link to="/" {...props} style={{ width: 191, display: "block" }}>
      <Logo />
    </Link>
  );
};
