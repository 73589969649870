import { graphql, useStaticQuery } from "gatsby";

export const usePlatformLinks = () => {
  const {
    site: {
      siteMetadata: { externalNavigation },
    },
  } = useStaticQuery(graphql`
    query ExternalNav {
      site {
        siteMetadata {
          externalNavigation {
            href
            name
            id
          }
        }
      }
    }
  `);
  return externalNavigation
}